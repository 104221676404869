import React,{ useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Button,
  lighten
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {BASE_API_URL} from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import Modal from "../../components/Modal";
import './../../styles/cssformui.css';

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell>ID</StyledTableCell>
        <StyledTableCell>NAME</StyledTableCell>
        <StyledTableCell>EMAIL</StyledTableCell>
        <StyledTableCell>MOBILE</StyledTableCell>
        <StyledTableCell>SPECIALIZATION</StyledTableCell>
        {/* <StyledTableCell>SPECIALIZATION</StyledTableCell> */}
        <StyledTableCell>ACTIONS</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({ value, onChange, onButtonClicked }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Vendors Data
      </Typography>

     {/*<div style={{ marginLeft: "auto" }}>
        <Modal type='Add' buttonText='Add Vendor' />
      </div>*/}
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
      <Link to="/vendor-register"><button className="btn-orange" >Add Vendor</button></Link>&nbsp;
          <OutlinedInput
            id="outlined-adornment-weight"
            startAdornment={<SearchIcon />}
            value={value}
            onChange={onChange}
            placeholder="Search"
            style={{ marginRight: '10px' }}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
            labelWidth={0}
          />
        </div>
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function VendorsData({ classes }) {
  const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setsearch] = useState('');
    const { userData, setUserData } = useContext(userContext);
    const [count, setcount] = useState(0);
    const [create, setcreate] = useState(false);
    const [delete1, setdelete] = useState(null);
    const [error, setError] = useState(null);
    const [update, setupdate] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getVendor = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
      `${BASE_API_URL}vendor-list?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}`, options);
    const data = response.json(); 
    data.then((data) => {
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }

      setRows(data.data.result);
      setcount(data.data.userCount);
      setLoading(false);
    })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getVendor();
  }, [search, create,rowsPerPage, page ]);

  return (
    <>
     {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
    <Paper style={{ margin: 10 }}>
    <EnhancedTableToolbar onButtonClicked={e => setcreate(true)} value={search} onChange={e => {setsearch(e.target.value); setPage(0)}} />
    <Divider />
    <TableContainer>
                <Table >
                  <EnhancedTableHead />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <StyledTableRow>
                          <StyledTableCell align='left'>{page*rowsPerPage+index+1}</StyledTableCell>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{row.firstName} {row.lastName}</StyledTableCell>
                          <StyledTableCell align='left'>{row.email}</StyledTableCell>
                          <StyledTableCell align='left'>{row.phoneNumber}</StyledTableCell>
                          <StyledTableCell align='left'>{row.specilization}</StyledTableCell>
                          {/* <StyledTableCell align='left'>
                            <Link to={{pathname: '/partner-details'}}>Gusie Watsan</Link>
                          <IconButton >
                            <AddIcon />
                          </IconButton>
                          </StyledTableCell> */}
                          <StyledTableCell align='left'>
                            <Link to={`/edit-vendor/${row._id}`} ><EditOutlinedIcon /></Link>
                            <DeleteOutlineIcon />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                    
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
    </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})
