import { uploadFile } from 'react-s3'

const AWS_REGION = "ap-south-1"
const AWS_BUCKET = 'eventstannew';
const AWS_ACCESS_KEY = 'AKIA5KND4OBV4VYU4SXF';
const AWS_ACCESS_SECRET = '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';

// const AWS_REGION = 'eu-west-2'
// const AWS_BUCKET = 'myeventstan'
// const AWS_ACCESS_KEY = 'AKIATQKLJMQQKXY5VXSI'
// const AWS_ACCESS_SECRET = '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'

export const uploadImageToS3 = (file, dirName) => {
    const config = {
        bucketName: AWS_BUCKET,
        dirName: dirName,
        region: AWS_REGION,
        accessKeyId: AWS_ACCESS_KEY,
        secretAccessKey: AWS_ACCESS_SECRET,
    }
    return uploadFile(file, config)
}